import * as React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import Section from '../components/Section';
import GangTitle from '../components/GangTitle';
import teamPhoto1 from '../images/team_1.png';
import teamPhoto2 from '../images/team_2.png';
import teamPhoto3 from '../images/team_3.png';
import teamPhoto4 from '../images/team_4.png';
import teamPhoto5 from '../images/team_5.png';

const Team = styled(Section)`margin: 0 auto; text-align: center; height: auto;

& .team-container{
  margin-top: 16px;

  @media screen and (min-width: 768px) {
    margin-top: 96px;
  }
}

`;
const TeamBao = styled.div.attrs({className: 'team-image'})`
 width: 160px;
 height: 160px;
 background: url('${teamPhoto1}') no-repeat;
 background-size: 100%;
 background-position: center;

    transition: all .5s ease-in-out;

`;

const TeamLink = styled.a.attrs({target:'_blank'})`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const TeamMember = styled.div`
 flex-basis: 20%;
 display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
    position: relative;
    padding: 8px;
    &:hover .team-image{ background-size: 120%; }

@media screen and (min-width: 550px){
 padding: 0px;
}
`;
const TeamMemberName = styled.div`
font-weight: 700;
color: #fff;
font-size: 32px;
margin: 16px 0 0;
`;
const TeamMemberRole = styled.div`
padding: 8px 0;
font-size: 14px;
`;

const TeamView = (props) => {
return <Team>
        <GangTitle style={{color: props.theme.lightGreen, fontSize:"80px"}}>THE TEAM</GangTitle>
        <div>The guys who work 24/7 to make it happen!</div>
          <Flex className='team-container' flexWrap={"wrap"} justifyContent={'center'}>
            <TeamMember>
            <TeamLink href='https://linktr.ee/cloudyugibao'/>
              <TeamBao style={{backgroundImage:`url('${teamPhoto1}')`}}/>
              <TeamMemberName>Cloud</TeamMemberName>
              <TeamMemberRole>Founder - Creative Director</TeamMemberRole>
            </TeamMember>

            <TeamMember>
            <TeamLink href='https://linktr.ee/kbanotes'/>
              <TeamBao style={{backgroundImage:`url('${teamPhoto2}')`}}/>
              <TeamMemberName>Kba-Notes</TeamMemberName>
              <TeamMemberRole>Founder - Managing Director</TeamMemberRole>
            </TeamMember>

            <TeamMember>
            <TeamLink href='https://linktr.ee/lcunillera'/>
              <TeamBao style={{backgroundImage:`url('${teamPhoto3}')`}}/>
              <TeamMemberName>Luigi</TeamMemberName>
              <TeamMemberRole>Marketing & Project Manager</TeamMemberRole>
            </TeamMember>

            <TeamMember>
              <TeamLink href='https://linktr.ee/miguelllorcabriganty'/>
              <TeamBao style={{backgroundImage:`url('${teamPhoto4}')`}}/>
              <TeamMemberName>MstrPinche</TeamMemberName>
              <TeamMemberRole>Art Director</TeamMemberRole>
            </TeamMember>

            <TeamMember>
            <TeamLink href='https://linktr.ee/malm_'/>
              <TeamBao style={{backgroundImage:`url('${teamPhoto5}')`}}/>
              <TeamMemberName>Mills</TeamMemberName>
              <TeamMemberRole>Concept Artist</TeamMemberRole>
            </TeamMember>

            
      
          </Flex>
        </Team>
}

export default TeamView