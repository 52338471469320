import * as React from 'react';
import styled from 'styled-components';

const Section = styled.section`
position: relative;
width: 100%;
height: 100%;
  display: flex;
text-align: left;
 
  flex-direction: column;
  max-width: 1080px;
  margin: 0 auto;
`;

const SectionComponent = (props) => {
    return <Section {...props}>{props.children}</Section>
}

export default SectionComponent