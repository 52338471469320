import styled from 'styled-components';
import React, { useState } from 'react';
import chevron from '../images/down-chevron.png'

// Styled-Components
const StyledAccordionSection = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  // max-height: 40px;
  overflow: hidden;
  transition: all 0.25s ease-out;
  margin: 0 auto;
  min-height: 40px;
  position: relative;
  box-sizing: border-box;

  &.toggled {
    max-height: 1080px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  & .accordion-title {
    position: relative;
    height: auto;
    height: 40px;
  }

  & .accordion-title {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 5px;
    margin: 0;
    border-top: 1px solid
      ${(props) => props.theme.background || 'var(--light-gray)'};
    border-width: 1px 0;
    transition: all 0.25s ease-out;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    z-index: 999999;
    box-sizing: border-box;
    cursor: pointer;
  }

  & .accordion-title:hover {
    background: rgba(0, 0, 0, 0.055);
  }

  & .accordion-title span {
    margin-left: 5px;
    flex-grow: 1;
  }

  &.toggled .accordion-title {
    transition: 0.1s all ease-in-out;
    border-bottom: none;
  }

  &.toggled .accordion-title:hover {
    background: ${(props) => props.theme.background || 'var(--light-gray)'};
    color: ${(props) => props.theme.darkestGray || 'var(--darkest-gray)'};
  }

  & .accordion-title svg ,  & .accordion-title img{
    transform: ${(props) => (props.icon ? 'rotate(0deg);' : 'rotate(0deg)')};
    transition: all 0.25s ease-out;
  }

  & .accordion-title img {
    width: 16px;
    transform: rotate(-90deg);
  }


  &.toggled .accordion-title img {
    transform: ${(props) => (props.icon ? 'rotate(0deg);' : 'rotate(0deg)')};
  }

  &.toggled .accordion-title svg{
    transform: ${(props) => (props.icon ? 'rotate(0deg);' : 'rotate(90deg)')};
  }

  & .accordion-body {
    max-height: 0;
    overflow: auto;
    transition: all 0.25s ease-out;
    padding: 0;
    opacity: 0;
  }

  &&&.toggled .accordion-body {
    max-height: 480px !important;
    overflow-y: auto;
    padding: 10px;
    opacity: 1;
    border-bottom: 1px solid
      ${(props) => props.theme.background || 'var(--light-gray)'};
  }

  &.accordionSection-container {
    background: ${(props) => props.theme.background || 'var(--medium-gray)'};
    flex: 0 0 100%;
  }

  &.top-open-container {
    max-height: 100%;
  }
`;

const Icon = styled.img`
  filter: invert(1);
`;

export default function AccordionSection(props) {
  const [expanding, setExpanding] = useState(true);
  const [toggled, setToggled] = useState(props.toggled);

  const toggleSection = (event) => {
    event.currentTarget.parentNode.classList.toggle('toggled');

    // This is only for the resizeWindow behavior
    const windowComponent = event.currentTarget;

    // while(!windowComponent.classList.contains("window")){
    //   windowComponent = windowComponent.parentNode;
    // }

    // Expands the containing window to reveal
    // as much of the content as possible, to reduce scrolling.
    // resizeWindow(windowComponent);
  };

  const className = toggled ? 'accordion-section toggled' : 'accordion-section';

  // className = props.title ? (className += " "+props.title) : false;

  return (
    <StyledAccordionSection
      {...props}
      key={props.title || Math.random()}
      className={`${className} ${props.name} ${props.className}`}
    >
      <div className="accordion-title" onClick={toggleSection}>
        <span>{props.title}</span>
        {props.icon || <Icon src={chevron}/>}
      </div>

      <div className="accordion-body">{props.children}</div>
    </StyledAccordionSection>
  );
}
