import styled from 'styled-components';

const GangTitle = styled.div`
position: relative;
font-size: 24px;
 font-family: 'gang_of_threeregular',serif;
 text-shadow: 0 0 2px black;


 &:first-child { margin-top: 50px;}
 &:nth-child(2) { margin-top: 24px; font-size: 20px;}

 @media screen and (min-width: 550px){
   font-size: 32px;
   // &:first-child{ margin-top: 0px;}
   &:last-child{ font-size: 28px;}

 }

 @media screen and (min-width: 769px){
   &:nth-child(2) { font-size: 28px;}
 }

 @media screen and (min-width: 1025px){
   font-size: 64px;
   &:first-child{ margin-top: 70px;}
   &:nth-child(2) { font-size: 56px;}
   
 }

 @media screen and (min-width: 1025px) and (height: 960px){
  // font-size: 36px;
  //  &:first-child{ margin-top: 16px;}
 }

 @media screen and (min-width: 1280px) and (max-height: 950px){
  // font-size: 36px;
  // &:first-child{ margin-top: 16px;}
 }
`;

export default GangTitle