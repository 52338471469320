import * as React from 'react';
import styled from 'styled-components';
import goldYugi from '../images/yugi-gold.png';
import Section from '../components/Section';
import GangTitle from '../components/GangTitle';

import goldYugiVideo from '../images/yugi-gold.mp4';

const Lore = styled(Section)`
    flex-direction: row;
    align-items: flex-start;
    &:before{
        content: "";
        display: block;
        height: 200px;
        width: 200%;
        background: #000;
        transform: translateX(-50%) rotate(-5deg);
        position: absolute;
        top: -75px;
        left: 50%;
    }

    & .lore-title { 
        font-size: 38vw;
        color: ${props => props.theme.lighterYellow};
        text-shadow: none;
        margin: 20px 0 0;

      
     }

     @media screen and (min-width: 960px){
        & .lore-title { font-size: 185px; }
      }

  
`;
const LoreContent = styled.div`

max-width: 100%;
position: relative;
text-align: justify;
width: 100%;
padding: 150px 16px 16px;
@media screen and (min-width: 768px){
    width: 410px;
    padding-right: 16px;
}
`;
const GoldYugi = styled.div`
width: 55%;
padding-top: 73%;
    background: url('${goldYugi}') no-repeat center/contain;

  
   
    position: relative;

    background-size: cover;

    @media screen and (min-width: 550px){
        position: relative;
        left: -5%;
        top: -5%;
    }
`;

const GoldYugiVideo = styled.video`


  object-fit: contain;
  object-position: center;
  clip-path: polygon(0% 10%,100% 10%,100% 95%,0% 95%);
   
    position: relative;




    position: absolute;
    top: -105px;
    left: 0;
    transform: none;
    width: 100%;

    @media screen and (min-width: 550px){
        width: 55%;
        position: relative;
        transform: translate(10%,-4%) scale(1.2);
        left: -5%;
        top: -5%;
    }
`;

const LoreView = (props) => {

    React.useEffect(()=>{
        document.querySelector(".gold-yugi-video").muted = true;
        document.querySelector(".gold-yugi-video").play();
    },[])

   return <Lore>
{/* <GoldYugi/> */}
<GoldYugiVideo muted="true" playsInline="true" loop="true" className='gold-yugi-video' src={goldYugiVideo}/>
<LoreContent>
<GangTitle className="lore-title">LORE</GangTitle>
<p>Circa 2,999 (the year of the YugiDragon), YugiBaos have developed consciousness and intelligence to a level that they are self-sufficient.</p> 
<p>To protect their soft, fluffy and steamy bodies, they have developed an exoskeleton called the MechBao, a type of personal robotic armor that keeps the YugiBao population safe from natural adversities and predators.</p>
<p>The <span style={{fontWeight:"bold", color: props.theme.lightRed}}>MechBao</span> keeps the YugiBao in a favorable ecosystem, whether in daily tasks or in the battlefield. At a temperature of 41 degrees Celcius and a humidity of 69%, the YugiBao feels as comfortable as in the bamboo basket that has traditionally been their home for thousands of years.</p>
<p>Of course, as any intelligent being living in a society, every YugiBao has their own personality and interest, which leads to all kind of situations any other society might relate to.</p>
</LoreContent>
</Lore>
}

export default LoreView
