import styled, {keyframes} from 'styled-components';
import letter_hat from '../images/letter_hat.svg';

export const spinAnimation = keyframes`
 from{
  transform:  translate(-50%,-50%) rotate(0deg);
 }
 to{
  transform:  translate(-50%,-50%) rotate(360deg);
 }
`;

export const Spinner = styled.div`
  width: 90px;
  height: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transform-origin: center;
  background: url('${letter_hat}') no-repeat center/contain;
  z-index: 3;
  transition: all 0.5s ease-in-out;

  animation: ${spinAnimation} 1s linear infinite;

  &.hidden{opacity: 0; transform: scale(0); pointer-events: none;}
`;

export default Spinner