import * as React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import Section from '../components/Section';
import GangTitle from '../components/GangTitle';
import Title from '../components/Title';
import AccordionSection from '../components/AccordionSection';

const Faq = styled(Section)`
height: auto;
padding:0;
box-sizing: border-box;


    max-width: 100%;

    & > div {
        max-width: 1080px;
        border: 1px solid #fff;
        border-width: 1px 0;
        padding: 50px 16px 50px 28px;

        @media screen and (min-width: 550px){
            padding: 50px 16px;
        }
    }

    &:before{
        content: "";
        display: block;
        position: absolute;
        left: 15px;
        width: 300px;
        transform: translate(-100%);
        top: 50px;
        background: ${props => props.theme.lighterYellow};
        height: calc(100% - 100px);
        border-radius: 12px;

        @media screen and (min-width: 550px){
            left: 20px;
        }
    }

    @media screen and (min-width: 550px){
        padding: 50px 10%;
    }
`;

const StyledAccordionSection = styled(AccordionSection)`
    width: 100%;
    padding: 8px 0;
    margin: 0;

    & .accordion-title { padding: 10px 0; }
    & .accordion-title span { margin: 0; padding-right: 8px;}
    &&& .accordion-body { padding: 8px 0 !important; }
`;

const FaqsView = (props) => {
return <Faq {...props}>
    <div style={{margin: '0 auto'}}>
            <Title style={{fontSize:"56px", margin: "0 0 16px", lineHeight:1}}>Frequently Asked Questions</Title>
       
        <StyledAccordionSection title={<Title style={{color: props.theme?.lightGreen}}>When is mint (steam) date?</Title>}>
       
       <p>February 9, 2023 on Magic Eden.</p>
        </StyledAccordionSection>

        <StyledAccordionSection title={<Title style={{color: props.theme?.lightRed}}>What’s the supply?</Title>}>
       <p>1,600 handcrafted, individually created 3D YugiBaos.</p>
            </StyledAccordionSection>

            <StyledAccordionSection title={<Title style={{color: props.theme?.lightGreen}}>What blockchain is the project going to launch on?</Title>}>
                
       <p>Solana Blockchain.</p>
       </StyledAccordionSection>

       <StyledAccordionSection title={<Title style={{color: props.theme?.lightRed}}>What’s the utility?</Title>}>
       <p>Since the very first moment of conceptualization, we started to visualize a whole universe and narrative where our characters can belong and thrive.</p>
       <p>The Yugiverse starts with the NFT Collection of 2,999 3D YugiBaos, each one with its own character, personality and style.</p>

<p>With the whole tribe deployed in the Solana Blockchain we will begin the production of a top notch animated pilot episode, that eventually will be fully produced to an Animated TV Show.</p>
</StyledAccordionSection>
<StyledAccordionSection title={<Title style={{color: props.theme?.lightGreen}}>Where will the secondary market happen?</Title>}>
    
       <p>Major marketplaces like Magic Eden, OpenSea, among others…</p>
       </StyledAccordionSection>
       </div>
        </Faq>
}

export default FaqsView
