import logo from './logo.svg';
import { useEffect, useState } from 'react';
import styled, {keyframes, createGlobalStyle, ThemeProvider} from 'styled-components';
import {Box, Flex} from 'rebass';
import './fonts.css';
import './App.css';

// import Section from './components/Section';
import Lore from './views/Lore';
import Team from './views/Team';
import Faqs from './views/Faqs';
import Title from './components/Title';
import GangTitle from './components/GangTitle';
import YugiComponent from './components/YugiComponent';
import YugiMap from './components/YugiMap';

import gsap, {CSSPlugin} from 'gsap';

import logoImage from './images/yugibao_logo_3d.png';
import yugiLogo from './images/yugibao_logo_final.png';
import yugiLogoFullscreen from './images/yugibao_logo_fullscreen.png';

import yugicartoon from './images/yugicartoon.png';
import yugiverse_background from './images/yugiverse_background.png';
import yugiverse_character from './images/yugiverse_character.png';

import kanjiVertical from './images/kanji_vertical.png';
import projectRoadmap from './images/webROADMAPv03.png';


import mouseIcon from './images/cursor.png';
import pointerIcon from './images/hand-pointer.png';
import scrollIcon from './images/scroll.png';
import twitterIcon from './images/twitter_icon.png';
import discordIcon from './images/discord_icon.png';
import instagramIcon from './images/instagram_icon.png';
import facebookIcon from './images/facebook_icon.png';
import tiktokIcon from './images/tiktok_icon.png';
import playIcon from './images/play-button.png';
import readIcon from './images/paper.png';

gsap.registerPlugin(CSSPlugin);

const theme = {
  lightRed: '#eb1e53',
  lightGreen: '#16c882',
  lightYellow: '#eac527',
  lighterYellow: '#e1fa11'
 }

 const GlobalStyles = createGlobalStyle`

 .App{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
 }

.container, .handcrafted-text, .steamed-text, .yugiverse-banner, .creating-text, .brought-text, #footer, .mouse-icon, .pointer-icon {
  visibility: hidden;
  opacity: 1;
  transition: opacity 1s ease-in-out;

  .user-is-touching & {
    opacity : 0;
  }
}

body{overflow: hidden;}
body.introReady{overflow: auto;}



`;

 const hoverAnimation = keyframes`
 from{
  transform: translateY(0);
}
to{
 transform: translateY(-16px);
}
 `;

 const scrollIconAnimation = keyframes`
 from{
  transform: translateY(0);
}
to{
 transform: translateY(-8px);
}
`;

const scrollOverAnimation = keyframes`
 0%{
  transform: translate(-100px);
}

100%{
  transform: translate(100px);
}
`;

 const smallHoverAnimation = keyframes`
 from{
   transform: translateY(0);
 }
 to{
  transform: translateY(-12px);
 }
`;

const blinkAnimation = keyframes`
 from{
  visibility: visible;
 }
 to{
  visibility: hidden;
 }
`;

const Section = styled.section`
position: relative;
width: 100%;
height: 100%;
  display: flex;
text-align: left;
 
  flex-direction: column;
  max-width: 1080px;
  margin: 0 auto;
`;

const Header = styled(Flex)`
  position: fixed;
  width: 100%;
  height: 60px;
  background: rgba(0,0,0,0.3);
  z-index: 3;
  color:#fff;
  padding: 16px;
  font-weight: 600;
  font-size: 18px;
  justify-content: space-between;
  display: none !important;
`;

const Footer = styled(Flex)`
position: relative;
bottom: 0;
width: 100%;
z-index: 2;
justify-content: center;
padding: 16px;
font-size: 12px;
color: #fff;
margin: 16px auto 0 !important;
max-width: 1080px;

@media screen and (min-width:550px){
 // justify-content: flex-end;
 font-size: 16px;
 margin: 24px auto 0 !important;
}

& .logo-footer{
  height: 70px;

  @media screen and (min-width: 768px){
    height: 120px;
  }
}
`;



const Container = styled.div`
  opacity: 1;
  color: #fff;
  font-size: 16px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px;
  box-sizing: border-box;
  transition: opacity 1s ease-in-out;

  .user-is-touching & {
    opacity: 0;
    transition-delay: 2s;
  }



`;

const BlackContainer = styled(Container)`
  background: #000;
  overflow: visible;
  height: auto;
`;


const Hero = styled(Section)`
font-size: 32px;
 align-items: center;
 justify-content: flex-end;
 text-align: center;
 height: 100vh;
`;

const Landing = styled(Section)`
&:before{
  content: "";
  position: absolute;
  width: 400%;
  height: 100%;
  transform: translateX(-50%);
  background: linear-gradient(to bottom, rgba(0,0,0,0),rgba(0,0,0,1));
}

`;

const About = styled(Section)``;
const Mission = styled(Section)``;


const Roadmap = styled(Section)`
  position: relative;
  display: block;
  padding: 0 16px;

  &:before{content:""; display: block; width: 16px; background: linear-gradient(to bottom, #fff, ${theme.lightRed}); position: absolute; top: 0; bottom: 0; left: 0; border-radius: 10px;}
  & .title{position: relative; font-size: 48px; line-height: 1; padding-left: 42px; margin: 8px 0 8px -16px; color:${theme.lightRed};}
  & .list-item{font-size: 24px; margin: 16px 0;}
  & .title{position: relative; padding-left: 42px; margin: 8px 0 8px -16px; color:${theme.lightRed};}
  & .title:before{content:""; display: block; width: 24px; height: 24px; background: ${theme.lightRed}; position: absolute; top: 50%; transform: translate(0, -50%); left: -4px; border-radius: 10px;}
`;

const ListItem = styled.div.attrs({className:"list-item"})`
 padding-left: 36px;
 &:before{content:"• "; display: inline-block; margin-right: 8px; font-size: 24px;}
`;

const Intro = styled(Section)`
 box-sizing: border-box;
 // padding: 50px 50% 50px 0;
 // max-width: 500px;
 // margin: 0 0 0 16px;
`;

const IntroContent = styled.div`
  max-width: 500px;
  margin: 50px 16px 100px;
  position: relative;

  & p { text-align: justify;}
`;

export const Button = styled.button`
  font-weight: 600;
  border: 2px solid #fff;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  color: #000;
`;

const CloseButton = styled(Button)`
background: ${props => props.theme.lighterYellow};
color: #000;
border: 2px solid #000;
margin-top: 16px;
position: absolute;
top: 8px;
right: 8px;
z-index: 9999;
`;


const JourneyMap = styled(Section)`
text-align: center;
`;



const PlayIcon = styled.div`
width: 36px;
height: 36px;
display: inline-block;
background: url('${playIcon}') no-repeat;
background-size: contain;
margin-right: 8px;
`;

const ReadMoreIcon = styled.div`
width: 36px;
height: 36px;
display: inline-block;
background: url('${readIcon}') no-repeat;
background-size: contain;
margin-right: 8px;
`;

const Modal = styled.div.attrs({className: 'modal'})`
  display: ${props => props.isOpen ? 'block' : 'none'};

  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999999999999999;
  background: rgba(0,0,0,0.8);
  top: 0;
  left: 0;

`;


const FanArt = styled(Section)`

& .paragraph{margin: 16px 0}

&:after{content:""; display:none; position: absolute; bottom: 0; width: 100vw; height: 800px; }

`;

const Closer = styled(Section)`
position: relative;
    background: linear-gradient(to bottom,rgba(0,0,0,0),#eb1e53);
    margin-top: -250px;
    z-index: -1;
    width: 100vw;
    max-width: unset;
    border-bottom: 100px solid #eb1e53;
`;


const Paragraph = styled.div.attrs({className:"paragraph"})`
  font-size: 24px;
  font-family: Helvetica, Arial, sans-serif;
`;

const Headline = styled.div.attrs({className:"headline"})`
font-family: 'gang_of_threeregular', serif;
font-size: 48px;
`;


const Logo3D = styled.div`
position: relative;
  width: 90%;
  height: 200px;
  background: url('${yugiLogo}') no-repeat center / contain;
  // height: 32.5%;
  //transform: translate(0%, -30%);
  

  .introReady &{
  animation: ${hoverAnimation} 5s infinite alternate ease-in-out;
  animation-delay: 0s;
  }

  @media screen and (min-width: 550px){
    position: relative;
  // width: 59%;
  // height: 36%;
  // top: 26.5%;
  // left: 50%;
  // transform: translateX(calc(-50%));
  width: 300px;
  height: 250px;
  }

  @media screen and (min-width: 769px){
    position: relative;
  // width: 59%;
  // height: 36%;
  // top: 26.5%;
  // left: 50%;
  // transform: translateX(calc(-50%));
  width: 800px;
  height: 200px;
  }

  @media screen and (min-width: 1025px){
    height: 300px;
  }

  @media screen and (min-width: 1025px) and (height: 960px){
    height: 250px;
  }

  @media screen and (min-width: 1280px) and (max-height: 950px){
    height: 250px;
  }
`;


const LogoFullscreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('${yugiLogoFullscreen}') no-repeat center / cover;
  height: 100%;
  background-position: 48% center;
  transform: scale(1.09) translate(0px, 6.5%);
  
  .introReady &{
  animation: ${hoverAnimation} 5s infinite alternate ease-in-out;
  animation-delay: 1s;
  }

  @media screen and (min-width: 550px){
    background-position: 43% center;
  }

`;


const HeroTitle = styled.div`
position: relative;
font-weight: 400;
font-size: 24px;
font-family: 'gang_of_threeregular',serif;
bottom: 0;
text-align: center;
line-height: 1;
color: ${props=> theme.lightYellow};
min-height: 30px;
line-height: 30px;
margin: 4px 0;


& div{display:inline; height: 0;}

@media screen and (min-width:550px){
  min-height: 30px;
line-height: 30px;
margin: 8px 0;
  & div{display:inline;}
}

@media screen and (min-width:769px){
  min-height: 30px;
line-height: 30px;

margin: 8px 0;
  & div{display:inline;}
}

@media screen and (min-width: 1025px){
  min-height: 50px;
  line-height: 50px;
  font-size: 36px;
  margin: 16px 0;
    & div{display:inline;}
}

@media screen and (min-width: 1025px) and (height: 960px){
  margin: 12px 0;
}

@media screen and (min-width: 1280px) and (max-height: 950px){
  margin: 8px 0;
  font-size: 32px;
}

  .introReady &{
  animation: ${hoverAnimation} 5s infinite alternate ease-in-out;
  animation-delay: 1s;
  }
`;


const Yugiverse = styled(Section)`
margin: 100px auto;
`;

const YugiverseCharacter = styled.div` position: absolute; top: 0; left: 50%; transform: translateX(-50%); background: url('${yugiverse_character}') no-repeat center / contain; width: 800px; height: 400px; z-index: 2;`;
const YugiverseBackground = styled.div`background: url('${yugiverse_background}') no-repeat center / contain; width: 90%; height: 420px; padding-top: 150px; margin: 0 auto;`;


const YugiCartoon = styled.div`
 width: 100%;
 height: 800px;
 background: url('${yugicartoon}') no-repeat center / contain;
`;

const KanjiVertical = styled.div`
  width: 100px;
  height: 212px;
  background: url('${kanjiVertical}') no-repeat center / contain;
`;


const SocialMedia = styled.div`
  padding: 8px 0;
  position: relative;
  right: 0;
  bottom: 0;
  z-index: 1;

  @media screen and (min-width: 550px){
  
  }
  @media screen and (min-width: 769px){

  }
  @media screen and (min-width: 1025px){
    padding: 0 0 16px;
  }
`;

const SocialIcon = styled.a.attrs({target:"_blank"})`
  cursor: pointer;

  position: relative;
 display: inline-block;
 width: 36px;
 height: 36px;
 background: url('${instagramIcon}') no-repeat center/contain;
 filter: invert(1);
 margin-right: 16px;

 &:last-child{margin: 0;}

 .introReady &{
   animation-name: ${smallHoverAnimation};
   animation-duration: 5s;
   animation-iteration-count: infinite;
   animation-direction: alternate;
   animation-timing-function: ease-in-out;
  }
`;

const ScrollIcon = styled(SocialIcon)`

 background-image: url('${scrollIcon}');
 margin: 0;
 .introReady &{
  animation-name: ${scrollIconAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
 }

 &&&.hidden { opacity: 0 !important;}

`;

const MouseIcon = styled(SocialIcon)`
display: none;
filter: none;
 background-image: url('${mouseIcon}');
 transition: opacity 2s ease-in;
 opacity: 1;
 .introReady &{
  animation-name: ${scrollOverAnimation};
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
 }
 &&&.hidden { opacity: 0 !important;;}
`;

const PointerIcon = styled(SocialIcon)`
display: none;
filter: none;
 background-image: url('${pointerIcon}');
 opacity: 1;
 transition: opacity 2s ease-in;
 .introReady &{
  animation-name: ${scrollOverAnimation};
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
 }
 &&&.hidden { opacity: 0 !important; transition: all .5s ease-in-out;}
`;

const DiscordIcon = styled(SocialIcon)`

 background-image: url('${discordIcon}');
 animation-delay: 0s;


`;

const FacebookIcon = styled(SocialIcon)`
 background-image: url('${facebookIcon}');
 animation-delay: 0.25s;
`;
const InstagramIcon = styled(SocialIcon)`
 background-image: url('${instagramIcon}');
 animation-delay: 0.5s;
`;
const TiktokIcon = styled(SocialIcon)`
 background-image: url('${tiktokIcon}');
 animation-delay: 0.75s;
`;
const TwitterIcon = styled(SocialIcon)`
 background-image: url('${twitterIcon}');
 animation-delay: 1s;

`;


const DiscordMessage = styled.div`
 display: flex;
 font-size: 16px;
 align-items: center;
 margin: 24px 0;
 .introReady &{
  animation-name: ${smallHoverAnimation};
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
 }
`;



const YugiverseBanner = styled.div`
  background: #3b02df;
  color: #fff;
  padding: 8px;
  width: 200%;
  text-transform: uppercase;
  font-weight: bold;
  margin: 48px 0;
  font-size: 12px;
  font-family: Courier New, monospace, Arial;
  transform: translateX(-25%);

  & section {font-size: inherit;}

  @media screen and (min-width: 320px){
    font-size: 14px;
  }

  @media screen and (min-width: 428px){
    & section {font-size: 14px !important; }
  }

  @media screen and (min-width: 550px){
    font-size: 16px;
  }
  @media screen and (min-width: 769px){
    margin: 48px 0;
  }

  @media screen and (min-width: 1025px){
    font-size: 32px;
   

  }

  @media screen and (min-width: 1025px) and (height: 960px){
    font-size: 24px;

  }

  @media screen and (min-width: 1280px) and (max-height: 950px){
    font-size: 24px;
   
  }

  & section{
    animation: ${blinkAnimation} 1.5s steps(5, start) infinite;
  }
`;

const BroughtToYou = styled.div`
position: relative;
  font-size: 16px;

  margin: 0 0 16px;

  & div{font-size: 12px; font-weight: bold; margin: 16px 0;}

  @media screen and (min-width: 550px){
    font-size: 18px;
    margin: 0 0 16px;
    & div{font-size: 14px; font-weight: bold; margin: 8px 0 0;}
  }

  @media screen and (min-width: 769px){
    
  }

  @media screen and (min-width: 1025px){
    font-size: 24px;
    margin: 0 0 16px;
    & div{font-size: 18px; font-weight: bold; margin: 16px 0 0;}
  }
 
  @media screen and (min-width: 1280px) and (max-height: 950px){
    font-size: 20px;
    & div{font-size: 16px; font-weight: bold; margin: 12px 0 0;}
  }
  
`;


const YugiTeaser = styled.video.attrs({src: 'yugiteaser_high.mp4', id: 'yugi-teaser', autoPlay: 'true', playsInline: 'true', muted: 'true', loop: 'true'})`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  max-height: 2000px;
  left: 0;
  object-fit: cover;
  clip-path: polygon(0% 15%,100% 0%,100% 85%,0% 100%);
  transition: all 2s ease-in-out;
  transition-delay: 0s;

  &.collapsed {
    max-height: 0;
  }

  & ~ .button {
   //  transform: translateX(-50%) scale(1) !important;
    transition: all .5s ease-in-out;
    transition-delay: 1s;
    opacity: 1;
  }
  &.collapsed ~ .button {
   // transform: translateX(-50%)  scale(0) !important;
    opacity: 0;
  }
`;

const ProjectRoadmap = styled.div`
  width: 100%;
  height: auto;
  min-height: 500px;
  background: url(${projectRoadmap}) no-repeat center / contain;
 // box-shadow: inset 0 0 10px 14px rgb(0 0 0);
`;

function App() {

  const [isLaunched, setLaunched] = useState(true)
  // const [conRoadmap, setConRoadmap] = useState(window.location.search === '?conroadmap' ? true : false)
  const [conRoadmap, setConRoadmap] = useState(false)
  const [videoModalOpen, setVideoModalOpen] = useState(false)

  useEffect(()=>{

    // Move most of this to YugiComponent.js
    let container = document.querySelector(".container");
    let sectionHeight = document.querySelector("section").clientHeight;
    let scrollDistance = document.querySelector(".scroll-distance");
    let scrollProgress = document.querySelector(".scroll-progress");
    let scrollCounter = document.querySelector(".scroll-counter");
    let scrollHeight = container.scrollHeight;
    window.delta = 0;

    let yugiVideo = document.querySelector("#yugi-video");
    window.video = yugiVideo;
    yugiVideo.muted = true;
    yugiVideo.defaultMuted = true;
    // yugiVideo.play();

    yugiVideo.addEventListener("timeupdate", (e) => {
      if(yugiVideo.paused === false){
        if (e.currentTarget.currentTime > 3.5) {
          window.videoPlayed = true;
          window.timeline.play();
         }
      }


      // Change video position after fade-out

      if(yugiVideo.paused === false){
        if (e.currentTarget.currentTime >= 4.5) {
          yugiVideo.classList.remove("centered");
         }
      }
      
    });

    yugiVideo.addEventListener("ended", (e) => {
        window.videoPlayed = true;
        e.currentTarget.currentTime = 5.3;
        e.currentTarget.play();
    });

    yugiVideo.addEventListener('canplaythrough', (event) => {
      document.querySelector("#spinner-icon").classList.add("hidden");
     });
 

    let scrollElement = document.querySelector(".App");
     
    scrollElement.addEventListener("scroll", (e)=>{
    
      //scrollDistance.textContent = container.scrollTop;
      let progress = ((scrollElement.scrollTop/(scrollHeight-scrollElement.clientHeight))*100).toFixed(3);
      //scrollProgress.textContent = progress+"%"; 
      window.progress = progress;
      let currentSlide = parseInt(scrollElement.scrollTop/sectionHeight);


      //scrollCounter.textContent = currentSlide;
     // console.log("Slide:"+currentSlide);
     //  console.log("Progress:"+progress);

     let yugiTeaser = document.querySelector('#yugi-teaser');

     console.log(yugiTeaser.getBoundingClientRect().top);

     if (yugiTeaser.getBoundingClientRect().top < (window.innerHeight - 100)) {
       yugiTeaser.classList.remove("collapsed");
       yugiTeaser.parentNode.classList.remove("collapsed");
     }

      if(window.darkenTimeline){
        window.darkenTimeline.progress(progress/100)
       }

      if(progress >= 70){
        window.landingTimeline.play();

        document.querySelector(".scroll-icon").classList.add("hidden");
       
        if (window.USER_IS_TOUCHING || window.innerWidth <= 550) {
          document.querySelector(".pointer-icon").classList.remove("hidden");
        } else {
          document.querySelector(".mouse-icon").classList.remove("hidden");
        }

  
      }
      window.oldDirection = e.currentTarget.scrollTop;
     // const direction = (window.e.currentTarget > window.oldDirection) ? 1 : 1;
     if(window.glft){

       window.mixer.setTime(3*(progress/100));
     }


  

      //Animate 3D Scene
    //     window.cubes.forEach((cube, ndx) => {
    //  // const speed = .2 + ndx * .1;
    //   const rot = 1 * progress;
    //   cube.rotation.x = rot;
    //   cube.rotation.y = rot;
    // });

   // window.yugi.position.z = (-1 * (1-(progress/100)));

  //  window.yugi.rotation.y = 1 * progress;

   // window.mixer.update(2 * (progress/100))

    });

    //let yugiVideo = document.querySelector("#yugi-video");



    window.darkenTimeline = gsap.timeline();
    window.darkenTimeline.pause();
    window.darkenTimeline.set(yugiVideo, {filter:'brightness(100%)', '-webkit-filter': 'brightness(100%)'});
    window.darkenTimeline.to(yugiVideo, {duration: 5, filter:'brightness(50%)', '-webkit-filter': 'brightness(50%)'});
   //  window.darkenTimeline.set(yugiTeaser, {maxHeight: '1000px'});
   // window.darkenTimeline.from(yugiTeaser, {maxHeight: '0px'});

    if(typeof window.timeline === "undefined"){
      
      let container = document.querySelector(".container");
      let footer = document.querySelector("#footer");
      let yugiLogo = document.querySelector("#logo-3d-image");
      let comingSoon = document.querySelector("#coming-soon-text");
      let scrollIcon = document.querySelector(".scroll-icon");
      let socialMedia = document.querySelector(".social-media");
      let handcraftedText = document.querySelector(".handcrafted-text");
      let steamedText = document.querySelector(".steamed-text");
      let yugiverseBanner = document.querySelector(".yugiverse-banner");
      let creatingText = document.querySelector(".creating-text");
      let broughtText = document.querySelector(".brought-text");
      let discordMessage = document.querySelector(".discord-message");
      let projectRoadmap = document.querySelector("#project-roadmap");

      projectRoadmap.style.height = (projectRoadmap.getBoundingClientRect().width/0.34749588)+"px";

      let allElements = document.querySelectorAll('.container, .handcrafted-text,.steamed-text,.yugiverse-banner,.creating-text,.brought-text, #footer, .mouse-icon, .pointer-icon');

      let comingSoonCharacters = [];
      for(let i = 0; i < comingSoon.textContent.length; i++){
        if (comingSoon.textContent[i] === " ") {

          comingSoonCharacters.push(`<div>&nbsp;</div>`)
        } else {

          comingSoonCharacters.push(`<span style="display:inline-block">${comingSoon.textContent[i]}</span>`)
        }
      }
      comingSoon.innerHTML = comingSoonCharacters.join('');

      let yugiVideo = document.querySelector("#yugi-video");
      
      window.timeline = gsap.timeline({onComplete: ()=>{
        window.animationComplete = true;
      }});
      window.landingTimeline = gsap.timeline({onComplete: ()=>{
       // window.animationComplete = true;
      }});

      
      // window.timeline.from(yugiVideo, {opacity: 0, onComplete:()=>{
      //   document.body.classList.add("introReady");
      // }});
      window.timeline.from(yugiLogo, {opacity: 0, duration: .5},"+=1.5");
      window.timeline.from(comingSoon.children, {opacity: 0, y:-50, stagger: 0.1});
      window.timeline.from(scrollIcon, {opacity: 0});
      window.timeline.from(discordMessage, {opacity: 0});
      window.timeline.from(socialMedia.querySelectorAll("a"), {opacity: 0,y:-50, stagger:0.1, onComplete:()=>{
           document.body.classList.add("introReady");
          
          }});


      window.landingTimeline.from(handcraftedText, {opacity: 0});
      window.landingTimeline.from(steamedText, {opacity: 0});
      window.landingTimeline.from(yugiverseBanner, {opacity: 0});
      window.landingTimeline.from(creatingText, {opacity: 0});
      window.landingTimeline.from(broughtText, {opacity: 0});
     // window.landingTimeline.from(footer, {opacity: 0});

      window.timeline.pause();
      window.landingTimeline.pause();

      allElements.forEach(el => {
        el.style.visibility = "visible";
      })
    } else if (isLaunched) {
      // let allElements = document.querySelectorAll('.container, #footer');

      // allElements.forEach(el => {
      //   el.style.visibility = "visible";
      // })
    }


  },[])

  return (<ThemeProvider theme={theme}>
    <div className={`App${window.location.search === "?dev" ? ' dev' : ''}`}>

      <GlobalStyles/>
    <Header>
      {/* <div className="scroll-counter">0</div>
      <div className="scroll-distance">0</div>
      <div className="scroll-progress">0</div>
      <div className="menu">MENU</div> */}
    </Header>
     <YugiComponent />
    
     <Container className='container'>
        {<Hero style={{height: "100vh"}}>
          <Logo3D id="logo-3d-image"/>
          <HeroTitle id="coming-soon-text"><span>Sold</span> <span>Out!</span></HeroTitle>
         
          <SocialMedia className="social-media">
          
          {/* <DiscordMessage className="discord-message">

          
          </DiscordMessage> */}
        <DiscordIcon  href="https://discord.com/invite/vbU6gJtbsG"/>
        <FacebookIcon href="https://www.facebook.com/YugiBaoNFT/"/>
        <InstagramIcon href="https://www.instagram.com/yugibaonft"/>
        <TiktokIcon href="https://www.tiktok.com/@yugibaonft"/>
        <TwitterIcon href="https://twitter.com/YugiBaoNFT"/>
        
      
      </SocialMedia>

      <ScrollIcon className="scroll-icon"/>
      {window.innerWidth > 768 && <MouseIcon className="mouse-icon hidden"/>}
      <PointerIcon className="pointer-icon hidden"/>
          
        </Hero>}

      {/* {<Landing style={{height:"auto", padding:"10vh 0 0", textAlign: "center", justifyContent: "center"}}>
        <GangTitle className="handcrafted-text" style={{color:theme.lightGreen}}><span style={{color:theme.lightRed}}>1,600</span> Handcrafted 3d Yugibaos</GangTitle>
          <GangTitle className="steamed-text" style={{color:theme.lightYellow}}>Steamed in the blockchain</GangTitle>
          <YugiverseBanner className="yugiverse-banner"><section>Currently Building The <span style={{color:theme.lightYellow}}>Yugiverse</span></section></YugiverseBanner>
          <BroughtToYou className="creating-text">Creating the first <span style={{color:theme.lightRed, fontWeight:"600"}}>Decentralized Digital Content Studio.</span>
          <div className="brought-text">Brought to you by Morgana Studios</div>
          </BroughtToYou>
        </Landing>} */}

        {isLaunched && <><Intro>
         
          <IntroContent>
          <GangTitle style={{color: theme.lighterYellow, fontSize:"80px", textShadow:'none'}}>YUGIBAO NFT</GangTitle>
        <p>
          YugiBAO is a collection of 1,600 non-autogenerated, individually created 3D YugiBaos, powered by Morgana Studios, a well-known VFX and 3D Animation Studio located in the heart of Madrid, Spain.
          </p>
          <p>
          We want to create an unique story-telling brand that drives creative content  the Blockchain. This journey starts with an NFT collection that will make YOU a part of this adventure: developing the first Decentralized Digital Content Studio together with us.
          </p>

          <Button style={{background: theme.lighterYellow, border: 'none',  transform:'scale(0.9)', display: "flex", alignItems:"center"}} onClick={() => {
           // window.open('https://vimeo.com/754418938/e54c611813')
         //  setVideoModalOpen(true);

            window.open("https://yugibao-1.gitbook.io/yugibao-nft-prewhitepaper")
          }}> <ReadMoreIcon/> Read More...</Button>
       
        
          </IntroContent>
          
        </Intro></>}
    
 </Container>
 
 <Modal isOpen={videoModalOpen} onClick={(e) => {
            setVideoModalOpen(false);
            if(e.target.classList.contains('modal')){
            }
          }}>
            <CloseButton onClick={() => {setVideoModalOpen(false)}}>CLOSE</CloseButton>
          <div style={{position: 'absolute', padding:'51.56% 0 0 0', width:"100%", top: '50%', left: 0, transform: 'translateY(-50%)'}}>
            <iframe src="https://player.vimeo.com/video/754418938?h=e54c611813&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position: 'absolute',top:'50%', transform: 'translateY(-50%)', left:'0', width: '100%', height: '100%'}}title="YugiBaoNFT Promo Teaser"></iframe></div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </Modal>
       <BlackContainer>
       <div  className="collapsed" style={{position:'relative', width: '100vw', zIndex: '999', transform:'translateY(-15%)'}}>
            <YugiTeaser id="yugi-teaser" className="collapsed"></YugiTeaser>
            <Button className="button" style={{position:'absolute', bottom:'15%', left:'50%', transform:'translateX(-50%) scale(0.9)', background: theme.lighterYellow, border: 'none', display: "flex", alignItems:"center"}} onClick={() => {
           // window.open('https://vimeo.com/754418938/e54c611813')
           setVideoModalOpen(true);
          }}><PlayIcon /> Watch Promo Video</Button>
            </div>
       <Lore  theme={theme}/>
        <JourneyMap>
          <GangTitle style={{color:theme.lightRed, textShadow:'none', fontSize:'80px'}}>ROADMAP</GangTitle>
          <div style={{marginBottom:'16px'}}>Explore the YugiMap to find out about the journey</div>

          <ProjectRoadmap id="project-roadmap"/>
          <GangTitle style={{marginTop:'64px', color: theme.lightYellow, fontSize: '50px', lineHeight: 1}}>Building The Yugiverse</GangTitle>
          <div style={{marginBottom:'16px'}}>The YugiBao Journey</div>
          <YugiMap />
           
        {conRoadmap &&  <Roadmap style={{minHeight: "unset", height: "auto", marginBottom:"200px"}}>
        <Headline style={{fontSize: "120px", color: theme.lightGreen, paddingLeft: "24px"}}>STEAM GUIDE</Headline>
        <Title>Launch YugiBao Website</Title>
        <ListItem>Creation and deployment of DAO Smart Contract + Collection.</ListItem>
        <ListItem>Pre-Roadmap + Lore (P1).</ListItem>
        <ListItem>Rarity Chart.</ListItem>
        
        <Title green>Community Building + Discord + Social Channels</Title>
        <ListItem>Collabs with well-established projects and DAOs</ListItem>
        <ListItem>Benefits pre mint for community inside the Discord:</ListItem>
        <ListItem>Contents - Games - Raffles.</ListItem>
        
        <Title>Organic + Sponsored Marketing Activities</Title>
        
        <Title green>Whitepaper launch</Title>
        <Title>Partnership with Magic Eden</Title>
        <Title green>MINT (STEAM) from YugiBao Website</Title>
        <ListItem>Dynamic Mint (Strata Protocol).</ListItem>
        <ListItem>Payment available with Credit Card.</ListItem>
        
        <Title>Collection is pre approved and listed in major marketplaces.</Title>
        <ListItem>Magic Eden, Open Sea, Solport.</ListItem>
        
        
        </Roadmap>}
        </JourneyMap>

        <Team theme={theme}/>

        <Faqs  theme={theme}/>
   
        
        {/* {isLaunched && <>
        <About>
        <Title>About</Title>
        <Headline>Lorem Ipsum <span  style={{color: theme.lightGreen}}>Dolor</span> Sit Amet</Headline>
        <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</Paragraph>
        </About>
        <Mission style={{alignItems:"flex-end"}}>
        <Title>Mission</Title>
        <Headline style={{fontSize:"120px", color: theme.lightRed}}>2,999</Headline>
        <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse <span style={{color: theme.lightGreen, fontWeight:"600"}}>ultrices gravida. </span>Risus commodo viverra maecenas accumsan lacus vel facilisis. 
        
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum <span style={{color: theme.lightRed, fontWeight:"600"}}>suspendisse ultrices</span> gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. 
        </Paragraph>
        </Mission>
       
        
        <Yugiverse>
        <YugiverseCharacter/>
        <YugiverseBackground/>
        <Flex style={{margin: "-170px auto 0"}}>
        <Box><KanjiVertical/></Box>
        <Box> <Headline style={{fontSize: "150px", lineHeight: "102px", color: theme.lightRed}}>Welcome</Headline>  
        <Headline style={{fontSize: "120px", lineHeight: "120px", color: theme.lightGreen}}><span style={{color:theme.lightYellow, fontFamily:""}}>Yugi</span>-Verse</Headline>  </Box>
        </Flex>
        
        </Yugiverse>
        
        <FanArt>
        <YugiCartoon />
        <Headline style={{fontSize: "200px", margin: "0 auto", color: theme.lightRed, fontStyle:"italic"}}>Fan Art </Headline>
        <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.   
        </Paragraph>
        <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.   
        </Paragraph>
        <Headline style={{color:theme.lightGreen, fontSize: "24px"}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.   </Headline>
        <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.   </Paragraph>
        <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. 
        </Paragraph>
        <Headline style={{color:theme.lightRed, fontSize: "24px"}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. 
        Lorem ipsum dolo
        </Headline>
        </FanArt>
      <Closer></Closer></>} */}
        <Footer id="footer">
        {/* <FacebookIcon href="https://www.facebook.com/YugiBaoNFT/"/>
        <InstagramIcon href="https://www.instagram.com/yugibaonft"/>
        <TiktokIcon href="https://www.tiktok.com/@yugibaonft"/>
      <TwitterIcon href="https://twitter.com/YugiBaoNFT"/> */}
      {/* <DiscordIcon/> */}

      {!isLaunched && <a>All Rights Reserved - Morgana Studios 2022.</a>}

      {isLaunched && <>
      <div style={{textAlign: 'center'}}><Logo3D className='logo-footer' style={{animation: 'none', margin: '0 0 16px'}}/>
       <div style={{fontStyle: "italic"}}>powered by</div>
       <div><a href="https://www.morganastudios.com" target='_blank' style={{color:'inherit',textDecoration:'none'}}>Morgana Studios</a></div>
       </div>
       <div style={{display:"flex", flexDirection:"column", position: 'absolute', top: '50%', right: '16px', transform:"translateY(-50%)", fontWeight:'bolder'}}>
       <a style={{margin: '0 0 16px', color:"#fff", textDecoration:"none"}} href="https://yugibao-1.gitbook.io/yugibao-nft-prewhitepaper/" target={"_blank"}>See Whitepaper</a>
       {/* <a style={{margin: '0 0 16px', color:"#fff", textDecoration:"none"}} href="#" target={'_blank'}>Read Manifesto</a> */}
       </div>
      </>}
      </Footer>
      </BlackContainer>
  
    </div>
    </ThemeProvider>);
}

export default App;
