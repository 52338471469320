import * as React from 'react';
import { useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import styled from 'styled-components';
import  Section  from './Section';
import { Spinner } from './Spinner';
const glbLoader = new GLTFLoader();
const fbxLoader = new FBXLoader();

const Yugi = styled(Section)`

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  z-index: 1;
  overflow: hidden;

 // & canvas{display: none;}

  & canvas{display: block; width: 100%; height: 100%; position: absolute; top:0; left:0; z-index: 9999;}

  &.active {z-index:0;}
`;


const YugiVideo = styled.video`

position: absolute;
top: 0;
left: 0;
object-fit: cover;
object-position: 48% top;
width: 100%;
height: 100%;
z-index: 999;
filter: brightness(100%);

&.centered{
  width: 100% !important;
  height: 70% !important;
  object-position: 48% !important;
  top: 50% !important;
  transform: translateY(-50%) translateZ(1px);

  @media screen and (min-width:550px){
    height: 100% !important;
    object-position: center !important;
  }
}

@media screen and (min-width: 550px){
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (min-width: 769px){
  height: 100%;
  object-fit: cover;
  object-position: center;
  object-position: center top;
}

@media screen and (min-width: 1025px){
  object-position: center top;
}

@media screen and (min-width: 1280px) and (max-height: 950px){
 
}
`;

const YugiComponent = (props) => {

    useEffect(()=>{

        const canvas = document.querySelector(".yugi-animation");
        const renderer = new THREE.WebGLRenderer({
          canvas:  canvas,
          antialias: true,
          powerPreference: "high-performance",
          precision: "lowp",
          logarithmicDepthBuffer: true
        });
      
        const fov = 50;
        const aspect = 1;  // the canvas default
        const near = 0.1;
        const far = 100;
        const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
        camera.position.z = 55;
        camera.position.y = 5;
      
        var cameraCenter = new THREE.Vector3();
      var cameraHorzLimit = 500;
      var cameraVertLimit = 500;
      var mouse = new THREE.Vector2();
      
      camera.lookAt (new THREE.Vector3(0,0,0));
          cameraCenter.x = camera.position.x;
          cameraCenter.y = camera.position.y;
      
          //set up mouse stuff
          window.addEventListener('touchstart', function onFirstTouch(e) {
            if(e.target.classList.contains('roadmap')) {
                return
            }
            
          //  document.body.classList.add('user-is-touching');
            window.document.querySelector("#yugibao-component").classList.add("active");
            window.USER_IS_TOUCHING = true;
            // window.removeEventListener('touchstart', onFirstTouch, false);
      
          }, false);
        
       //   window.document.addEventListener('mousemove', onMouseMove, {passive: false});
       //   window.document.addEventListener('touchmove', onTouchMove, {passive: false});
       //   window.document.addEventListener('touchend', onTouchEnd, {passive: false});
      //    window.document.addEventListener('mouseup', onTouchEnd, {passive: false});
         // document.addEventListener('touchmove', onMouseMove, false);
          window.addEventListener('resize', onWindowResize, false);
      
        const scene = new THREE.Scene();
        scene.add(new THREE.AxesHelper(5));
      
        
        window.scene = scene;
      
      
      //   function updateCamera() {
      //     //offset the camera x/y based on the mouse's position in the window
      //     camera.position.x = cameraCenter.x + (cameraHorzLimit * mouse.x);
      //     camera.position.y = cameraCenter.y + (cameraVertLimit * mouse.y);
      // }
      
      function animateVideoOnHover(){
        let yugiVideo = document.querySelector("#yugi-video");
        if(window.videoPlayed && window.mouseVideoProgress && window.animationComplete){
          let videoDuration = yugiVideo.duration;
          //  console.log(yugiVideo.duration);
      
            if(!yugiVideo.paused){
              yugiVideo.pause();
            }
            yugiVideo.currentTime = 5.3 + (window.mouseVideoProgress * (videoDuration - 5.3));
        } else if (window.videoPlayed && window.mouseVideoProgress === null){
          yugiVideo.currentTime = 5.3;

          if(yugiVideo.paused){
              yugiVideo.play();
            }
        }
      }
      
      //window.requestAnimationFrame(animateVideoOnHover)
      setInterval(animateVideoOnHover, 50);
      
      
      function onTouchMove(event) {
       
       // mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
       // mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        if(typeof window.playInterval !== 'undefined'){
          clearInterval(window.playInterval);
        }
      
        let mouseVideoProgress = 1+((event.touches[0].clientX - window.innerWidth)/window.innerWidth);
        window.mouseVideoProgress = mouseVideoProgress;
        let yugiVideo = document.querySelector("#yugi-video");
      
      
        
      
        let videoDuration = yugiVideo.duration;
      //  console.log(yugiVideo.duration);
        if(window.videoPlayed){
         // yugiVideo.pause();
         // yugiVideo.currentTime = 6 + (mouseVideoProgress * (videoDuration - 6));
        }
      
        window.playInterval = setInterval(()=>{ 
          window.mouseVideoProgress = null;
         // yugiVideo.play();
        }, 3000);
      
      }
      
      function onTouchEnd(event) {
        document.querySelector("#yugibao-component").classList.remove("active");
        document.body.classList.remove("user-is-touching");
        window.USER_IS_TOUCHING = false;
      }
      function onMouseMove(event) {
         // event.preventDefault();
         // mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
         // mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
         if(typeof window.playInterval !== 'undefined'){
          clearInterval(window.playInterval);
        }
      
          let mouseVideoProgress = 1+((event.clientX - window.innerWidth)/window.innerWidth);
          window.mouseVideoProgress = mouseVideoProgress;
          let yugiVideo = document.querySelector("#yugi-video");
        
      
          //setInterval(()=>{ yugiVideo.play();}, 5000)
      
          let videoDuration = yugiVideo.duration;
        //  console.log(yugiVideo.duration);
        if(window.videoPlayed){
         // yugiVideo.pause();
         // yugiVideo.currentTime = 6 + (mouseVideoProgress * (videoDuration - 6));
        }
        window.playInterval = setInterval(()=>{ 
           window.mouseVideoProgress = null;
          //yugiVideo.play();
        }, 3000);
      }
      
      function onWindowResize() {
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();
        //  renderer.setSize(window.innerWidth, window.innerHeight);
      }
      
        // const boxWidth = 1;
        // const boxHeight = 1;
        // const boxDepth = 1;
        // const geometry = new THREE.BoxBufferGeometry(boxWidth, boxHeight, boxDepth);
        // const loader = new THREE.TextureLoader();
      
        //Load Yugi Model
        // let mixer : THREE.AnimationMixer;
      
      //   const fbxLoader = new FBXLoader()
      // fbxLoader.load(
      //     'RUNNING_YUGI.fbx',
      //     (object) => {
      //         // object.traverse(function (child) {
      //         //     if ((child as THREE.Mesh).isMesh) {
      //         //         // (child as THREE.Mesh).material = material
      //         //         if ((child as THREE.Mesh).material) {
      //         //             ((child as THREE.Mesh).material as THREE.MeshBasicMaterial).transparent = false
      //         //         }
      //         //     }
      //         // })
      //         // object.scale.set(.01, .01, .01);
      //         console.log(object);
      //         scene.add(object)
      //     },
      //     (xhr) => {
      //         console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
      //     },
      //     (error) => {
      //         console.log(error)
      //     }
      // )
      if(window.location.search === "?dev" || window.location.search === "?launchedd"){
      

        fbxLoader.load('RUNNING YUGI_STANDARD_.fbx', function(fbx){
            console.log(fbx);
            scene.add(fbx);
            const light = new THREE.AmbientLight(0xffffff);
            scene.add(light);
        })
      
         glbLoader.load('./RUNNING_YUGI.gltf', function (gltf) {
        
          const light = new THREE.AmbientLight(0xffffff);
          const areaLight1 = new THREE.RectAreaLight(0xffffff, 100, 10, 5);
          areaLight1.position.set(0,25, -100);
         // const light = new THREE.AmbientLight(0xFFFFFF);
          const pointLight1 = new THREE.PointLight( 0xffffff, 12, 300, 2 );
          const pointLight2 = new THREE.PointLight( 0xffffff, 7, 300, 2 );
          const sphere = new THREE.SphereGeometry( 0.5, 16, 8 );
          pointLight1.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xe60d56 } ) ) );
          pointLight2.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xe60d56 } ) ) );
          // pointLight1.position.set( -200, 120, 270 );
          // pointLight2.position.set( -200, 120, 270 ); 
          pointLight1.position.set( 0, 120, 0 );
          pointLight2.position.set( 0, 120, 0 );
           light.position.set( 0, 0, 0 );
      
         //hsl(344deg 78% 55%)
      
         gltf.cameras[0].aspect = 1;
         gltf.cameras[0].fov = 50;
        // gltf.cameras[0].position.x = (-81.76253509521484);
        // gltf.cameras[0].position.y = (71.54324340820312);
        // gltf.cameras[0].position.z = (63.61745071411133);
      
      
          scene.add( light );
          scene.add( pointLight1 );
          scene.add( pointLight2 );
          scene.add( areaLight1 );
      
        //  window.controls = new OrbitControls(gltf.cameras[0], renderer.domElement)
        //  window.controls.enableDamping = true
        //  window.controls.target.set(0, 40, 0);
      
          window.screenCanvas = document.createElement("canvas");
          window.screenCanvas.style.background = "red";
          const ctx = window.screenCanvas.getContext('2d');
          ctx.font = "36px 'Courier New', serif";
          ctx.fillStyle = "black";
          ctx.filter = 'blur(1.5px)';
          ctx.fillRect(0, 0, window.screenCanvas.width, window.screenCanvas.height);
          ctx.fillStyle = "yellow";
          ctx.fillText('WAGMI', 10, 50);
      
      
          var animationDirection = 1;
          var rmapped = 0;
          const animateLight = ()=>{
      
              pointLight1.position.x += 5*(animationDirection);
              pointLight2.position.z += 5*(animationDirection);
      
              var h = rmapped * 0.01 % 1;
              var s = 0.5;
              var l = 0.5;
              pointLight1.color.setHSL ( h, s, l );
             // pointLight2.color.setHSL ( h*-1, s, l );
              rmapped ++;
          
      
            if(pointLight1.position.x < -200){
              pointLight1.position.x = -200;
              pointLight2.position.z = -200;
              animationDirection = 1;
            //  window.mixer.timeScale = 0.02;
            }
             
            if(pointLight1.position.x > 200){
              pointLight1.position.x = 200;
              pointLight2.position.z = 200;
              animationDirection = -1;
            //  window.mixer.timeScale = -0.02;
            }
            
          }
      
          setInterval(animateLight, 60);
      
          // gsap.fromTo(pointLight, {opacity: 1, onUpdate:(vars)=>{
          //   console.log(vars);
      
          // }});
          
          gltf.scene.scale.set(0.8,0.8,0.8);
          gltf.scene.position.y = 0;
          gltf.scene.position.z = 0;
          window.ambient_light = light;
          window.point_light1 = pointLight1;
          window.point_light2 = pointLight2;
          window.area_light1 = areaLight1;
          window.yugi = gltf.scene;
          window.gltf = gltf;
      
      
          //  window.yugi.children[0].children[0].children[0].children[1].children[2].children[2].children[4].material.transparent = true;
          //  window.yugi.children[0].children[0].children[0].children[1].children[2].children[2].children[4].material.opacity = 0.3;
      
          //   window.yugi.children[1].visible = false;
          //   window.yugi.children[2].visible = false;
          //   window.yugi.children[3].visible = false;
          const screenTexture = new THREE.CanvasTexture(window.screenCanvas);
         screenTexture.minFilter = THREE.LinearFilter;
         screenTexture.rotation = THREE.MathUtils.degToRad(193);
         screenTexture.wrapS = THREE.RepeatWrapping;
          screenTexture.wrapT = THREE.RepeatWrapping;
          screenTexture.center.set(1,1);
          screenTexture.repeat.set(-2, 2.6);
          screenTexture.offset.set(-0.05,0);
      
          let material = new THREE.MeshPhongMaterial({ color: "green", map: screenTexture,
          transparent: false,
          emissive: new THREE.Color(0xffffff),
          emissiveMap: screenTexture
        });
          // material.map = new THREE.CanvasTexture().load('big_clank.png');
        //  material.map = screenTexture;
         
          let mesh = null;
          let getMesh = (parent) => {parent.children.forEach(ch => {
            if(ch.name === "robotitoRIG_0059detallesTapa1-RS_Material8") {
              mesh = ch;
            //  return ch
            }
      
            if(ch.children.length > 0){
              if(ch.children.find(c => c.name === "robotitoRIG_0059detallesTapa1-RS_Material8" ? true : false)){
                mesh = ch.children.find(c => {
                  return c.name === "robotitoRIG_0059detallesTapa1-RS_Material8" ? true : false
                });
                // return ch.children.find(c => {
                //   return c.name === "robotitoRIG_0059detallesTapa1-RS_Material8" ? true : false
                // });
              } else {
               ch.children.forEach(cx => { getMesh(cx);});
              }
            }
            if(mesh !== null){
             // return mesh;
            } else {
            //  return mesh;
            }
          });
      
      
         
        }
      
        setTimeout(() => {
      
       //   getMesh(window.scene);
        //  console.log(mesh);
         // mesh.material = material
        } , 1000)
      
         //  scene.overrideMaterial = material;
        //  scene.add(gltf.scene);
      
      
          // window.mixer : THREE.AnimationMixer = new THREE.AnimationMixer(window.gltf.scene);
          window.mixer = new THREE.AnimationMixer(window.gltf.scene);
          window.mixer.timeScale = 0.5;
         
          console.log('Starting mixer for:' + window.mixer.duration);
         
          // mixer.stopAllAction()
          window.gltf.animations.forEach(
              (clip) => {
                
               window.mixer.clipAction(clip).clampWhenFinished = true;
             //   window.mixer.clipAction(clip).setLoop(THREE.LoopPingPong);
                window.mixer.clipAction(clip).play()
              }
          );
      
      
      //     // Play a specific animation
      // const clip = THREE.AnimationClip.findByName( gltf.animations, '"animation_0"' );
      // const action = window.mixer.clipAction( clip );
      // action.play();
          
        
        }, undefined, function (error) {
        
          console.error( error );
        
        } ); 
      
      
       function resizeRendererToDisplaySize(renderer) {
          const canvas = renderer.domElement;
          const width = canvas.clientWidth;
          const height = canvas.clientHeight;
          const needResize = canvas.width !== width || canvas.height !== height;
          if (needResize) {
            renderer.setSize(width, height, false);
          }
          return needResize;
        }
      /*
      */
         const clock = new THREE.Clock();
      
        function render(time) {
          time *= 0.001;
      
          if (resizeRendererToDisplaySize(renderer)) {
            const canvas = renderer.domElement;
            // camera.aspect = canvas.clientWidth / canvas.clientHeight;
            camera.updateProjectionMatrix();
          }
      
         //window.mixer.update(clock.getDelta())
          if(window.gltf){
          // console.log(clock.getDelta());
         //  window.mixer.update(clock.getDelta())
             window.mixer.setTime(4*(window.progress/100));
           //  console.log(progress);
         //  window.controls.update()
           renderer.render(scene, window.gltf.cameras[0]);
          }else{
            renderer.render(scene, camera);
          }
          // updateCamera();
        
         
          requestAnimationFrame(render);
        }
      
      
        
        
      
        requestAnimationFrame(render);
      }
      
        },[])

    return (<Yugi style={{position: "fixed"}} id="yugibao-component">
<Spinner id="spinner-icon"/>
<canvas className="yugi-animation"></canvas>
<YugiVideo className="centered" src="yugi_speedtrail.mp4" autoPlay="true"  muted="true" playsInline="true" defaultMuted="true" id="yugi-video"></YugiVideo>
{props.children}
</Yugi>)
}

export default YugiComponent