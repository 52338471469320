import * as React from 'react';
import styled from 'styled-components';
import roadmap from '../images/roadmap.png';
import {Button} from '../App';

const YugiMap = styled.div`
position: relative;

    min-height: 100px;
    height: calc(100vw / 1.777);
    background: url('${roadmap}') no-repeat center/contain;
    margin: 50px 0 20px;

    @media screen and (min-width: 550px)
{
    width: 100%;
    min-height: 600px;
}`;


const Magnifier = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
width: 200px;
height: 200px;
background: url('${roadmap}') no-repeat;
background-size: 1920px 1080px;
background-position: 0% 0%;
pointer-events: none;
border: 5px solid #fff;
box-shadow: 0 3px 3px 0px rgba(0,0,0,0.1);
border-radius: 50%;
visibility: hidden;
transition: width .3s ease-in-out, height .3s ease-in-out;
`;

const YugiExplorer = (props) => {

    const onHover = (e) => {

        e.preventDefault();

        if(e.type === 'touchmove'){
          
        }

        let clientX = e.type === "touchmove" ? e.changedTouches[0].clientX : e.clientX;
        let clientY = e.type === "touchmove" ? e.changedTouches[0].clientY : e.clientY;

        magnifierRef.current.style.position = 'fixed';
        magnifierRef.current.style.left = clientX + "px";
        magnifierRef.current.style.top = clientY + "px";
        magnifierRef.current.style.width = '200px';
        magnifierRef.current.style.height = '200px';
        magnifierRef.current.style.visibility = 'visible';

        // Calculate mouse position on map

        let mapWidth = mapRef.current.getBoundingClientRect().width;
        let mapHeight = mapRef.current.getBoundingClientRect().height;
        let mapTop = mapRef.current.getBoundingClientRect().top;
        let mapLeft = mapRef.current.getBoundingClientRect().left;

        let xProportion =((clientX - mapLeft)/ mapWidth) * 100;
        let yProportion =((clientY- mapTop)/ mapHeight) * 100;

        console.log(clientX, clientY, xProportion, yProportion);

        magnifierRef.current.style.backgroundPosition = `${xProportion}% ${yProportion}%`;
    }

    const clearMap = () => {
        mapRef.current.removeEventListener("mousemove", onHover);
        mapRef.current.removeEventListener("touchmove", onHover);
        magnifierRef.current.style.position = 'absolute';
        magnifierRef.current.style.top = '0';
        magnifierRef.current.style.left = '0';
        magnifierRef.current.style.width = '0';
        magnifierRef.current.style.height = '0';
        magnifierRef.current.style.visibility = 'hidden';
    }

    const activateMagnifier = () => {
        mapRef.current.addEventListener("mousemove", onHover);
        mapRef.current.addEventListener("touchmove", onHover);
    }

    const mapRef = React.createRef();
    const magnifierRef = React.createRef();

    React.useEffect(() => {
        mapRef.current.style.height = mapRef.current.getBoundingClientRect().width/1.77 + 'px';
    },[]);

   return  <><YugiMap className="roadmap" onMouseOut={clearMap} onMouseEnter={activateMagnifier} onTouchStart={activateMagnifier} onTouchEnd={clearMap} ref={mapRef}>
        <Magnifier ref={magnifierRef}/>
    </YugiMap>
    <Button style={{margin:"32px auto 0", background:"transparent", color:"#fff"}} onClick={()=>{window.open('roadmap.jpg')}}>Explore our Map</Button></>
}

export default YugiExplorer

